import React, { useEffect } from 'react'
import refer from '../assets/refer-earn.svg'

export default function ReferEarn() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className='px-[1rem] sm:px-[3rem] md:max-lg:px-[1rem] lg:px-[3rem] xl:px-[5rem]'>
      <div className='flex md:flex-row flex-col items-center justify-between w-full max-md:gap-[1rem] 
      my-[1.5rem] sm:my-[2rem] md:my-0'>
        <div className='w-full md:w-[45%] lg:w-[40%]'>
          <h1 className='text-primary text-[2.2rem] lg:text-[2.5rem] max-md:text-center font-bold'>
            Refer and Earn
          </h1>
          <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] max-md:text-center 
        mt-[1rem] lg:leading-9'>
            Please login to your account and provide the information of the person's who you would like to refer to Eazy Refund Tax.
          </p>
          <p className='text-secondary text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] max-md:text-center 
        mt-[1rem] lg:leading-9'>
            If you are not registered yet with Eazy Refund Tax then please Register and start referring the friends. This will help you track the status of your friends in real time.
          </p>
          <div className='flex my-[0rem] sm:my-[1rem]'>
            <a href="https://www.client.eazyrefundtax.com/web/registrationform">
              <button className='py-[0.3rem] sm:py-[0.5rem] px-[1rem] sm:px-[3rem] bg-primary text-white rounded-[6px] mt-[1.5rem] font-[500]'
                type="submit"
              >
                Click Here
              </button>
            </a>
          </div>
        </div>
        <div className='w-[98%] sm:w-[80%] md:w-[50%] xl:w-[45%]'>
          <img src={refer} alt="" />
        </div>
      </div>
      <div className='flex justify-center my-[2rem]'>
        <table className='border border-[#D1D4D7]'>
          <thead>
            <tr className='text-primary text-[0.85rem] xs:max-lg:text-[1.1rem] lg:text-[1.2rem]'>
              <th className='border border-[#D1D4D7] px-[0.5rem] sm:max-md:px-[2rem] md:px-[5rem] py-[0.5rem]'>
                Number of Successful Referees
              </th>
              <th className='border border-[#D1D4D7] px-[0.5rem] sm:px-[3rem] md:px-[6rem] py-[0.5rem]'>Amount of Bonus1
              </th>
            </tr>
          </thead>
          <tbody className='text-[0.85rem] xs:max-lg:text-[1rem] lg:text-[1.1rem] font-[500]'>
            <tr>
              <td className='border border-[#D1D4D7] text-center py-[0.4rem] px-[0.2rem]'>1 - 10 Referrals</td>
              <td className='border border-[#D1D4D7] text-center py-[0.4rem] px-[0.2rem]'>$10 per successful referral</td>
            </tr>
            <tr>
              <td className='border border-[#D1D4D7] text-center py-[0.4rem] px-[0.2rem]'>11 - 20 Referrals</td>
              <td className='border border-[#D1D4D7] text-center py-[0.4rem] px-[0.2rem]'>$15 per successful referral</td>
            </tr>
            <tr>
              <td className='border border-[#D1D4D7] text-center py-[0.4rem] px-[0.2rem]'>More Than 20 Referrals</td>
              <td className='border border-[#D1D4D7] text-center py-[0.4rem] px-[0.2rem]'>$20 per successful referral</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='my-[2rem]'>
        <h2 className='text-[1.2rem] sm:text-[1.6rem] lg:text-[2rem] font-bold my-[1rem]'>
          Referral Bonus Policy:
        </h2>
        <ul className='list-disc flex flex-col gap-[1rem] text-[0.9rem] xs:max-sm:text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] font-[500] pl-[1.5rem] sm:pl-[2rem]'>
          <li>
            The customer has a right to redeem the referral bonus at any time if the referral bonus reaches to $200.
          </li>
          <li>
            The total Referral Bonus amount will get accumulated and be transferred to the bank account after 15th April only.
          </li>
          <li>
            One must first register and then start referring the friends.
          </li>
          <li>
            The referrer can track the list of referees, status of the referees and referral bonus earned etc., from his login account.
          </li>
          <li>
            The referral bonus will be offered only upon receipt of payment from the referee.
          </li>
          <li>
            The referral bonus scheme is applicable for those who are referred through Refer a Friend page and shall not be applicable for oral reference.
          </li>
          <li>
            Bonus is calculated according to the chart above.
          </li>
        </ul>
      </div>
    </div>
  )
}
