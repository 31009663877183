import React from 'react'
import { Icon } from '@iconify/react';
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function ITINProcessing() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"ITIN Processing"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <h1 className='text-primary font-bold text-[1.1rem] sm:text-[1.3rem] lg:text-[1.5rem] lg:leading-8'>
            What are the conditions in which a non-US person needs an ITIN?
          </h1>
          <p>
            Most of the people don't know the circumstances during which a non-US person need an ITIN and  what an ITIN exactly implies.
          </p>
          <p>
            Usually, they are confused with the words ITIN (Individual Taxpayer Identification Number) and EIN (Employer Identification Number). The EIN number will be used for the company’s tax purposes whereas an ITIN will be used for the individuals. IN most cases, the ITIN will be termed as Social  Security Number (SSN).
          </p>
          <p>
            The SSN entitles residents or native citizens of the USA to work in the US. On the other hand, ITIN is required for the individuals for whom the US taxpayer ID is required.
          </p>
          <ul className='flex flex-col gap-[1rem]'>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                You are required to file an income tax return
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                You own an organization in the country and you are required to use a commercial credit card.
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                You are a dependent of a non-US citizen.
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                You have the tax filing obligation in the US.
              </span>
            </li>
            <li className='flex items-start gap-[0.5rem] xs:gap-[1rem]'>
              <Icon icon="mdi:star-four-points" className='text-primary mt-[0.3rem] shrink-0' />
              <span>
                You own any sort of US trade or possess connected income in the USA.
              </span>
            </li>
          </ul>
          <p>
            The process of obtaining an ITIN number is not simple. So, it is always advisable to hire an experience with relevant experience before you are going to embark on the process of applying for an ITIN.
          </p>
        </div>
      </ServicesCard>
    </div>
  )
}
