import React from 'react'
import { Icon } from '@iconify/react';
import customer1 from "../../assets/home/customer1.svg"
import customer2 from "../../assets/home/customer2.svg"
import customer3 from "../../assets/home/customer3.svg"
import StarRatings from 'react-star-ratings';
import homepage3 from '../../assets/home/homepage3.png'
import { Link } from 'react-router-dom';
// import homepage4 from '../../assets/homepage4.png'
// import homepage5 from '../../assets/homepage5.png'

export default function Homepage() {

  return (
    <div className='flex flex-col md:flex-row items-center justify-between max-md:mt-[1.5rem]'>
      <div className='flex flex-col gap-[0.8rem] md:gap-[1.5rem] max-md:mx-[0.5rem] md:ml-[1rem] lg:ml-[5rem]'>
        <h1 className='font-bold text-[1.3rem] sm:text-[2rem] lg:text-[2.5rem]'>
          Get Free Refund <span className='text-primary'>Estimation</span>
          <br className='' /> Within One Hour
        </h1>
        <p className='text-[#8888A3] text-[0.9rem] sm:text-[1.2rem]'>
          The Fastest Service Available
        </p>
        <div className='flex gap-[1.5rem] xs:gap-[2rem] items-center'>
          <span className='border border-[#D1D4D7] pl-[1.5rem] sm:pl-[2rem] pr-[5rem] sm:pr-[7rem] 
          py-[0.5rem] sm:py-[0.6rem] rounded-[40px] text-center whitespace-nowrap'>
            Get Your Estimate
          </span>
          <Link to={"/refund-estimate"}>
            <button className='flex justify-center items-center h-[3rem] w-[3rem] bg-primary text-white 
          text-[1rem] font-bold rounded-full'>
              <Icon icon="formkit:arrowright" />
            </button>
          </Link>
        </div>
        <div className='flex gap-[0.5rem] md:max-lg:gap-[0.3rem] lg:gap-[1.5rem] items-center'>
          <div className='flex'>
            <img
              className='h-[4rem] w-[2.5rem] sm:w-[3rem] lg:w-[4rem] mr-[-1.1rem] sm:mr-[-1.4rem] z-20'
              src={customer1} alt="customer" />
            <img
              className='h-[4rem] w-[2.5rem] sm:w-[3rem] lg:w-[4rem] mr-[0rem] xs:mr-[-1.4rem] z-10'
              src={customer2} alt="customer" />
            <img className='h-[4rem] w-[2.5rem] sm:w-[3rem] lg:w-[4rem] max-xs:hidden'
              src={customer3} alt="customer" />
          </div>
          <div className=''>
            <h1 className='text-[1.1rem] sm:text-[1.2rem] lg:text-[2rem] font-semibold'>22,291</h1>
            <p className='text-[#8888A3] text-[0.8rem] sm:text-[1rem] whitespace-nowrap'>Happy Customers</p>
          </div>
          <span className='w-[2px] h-[50px] bg-[#8888A3]'></span>
          <div className=''>
            <h1 className='text-[1.1rem] sm:text-[1.2rem] lg:text-[2rem] font-semibold'>4.9/5</h1>
            <div className='flex items-center flex-wrap gap-[0.5rem]'>
              <StarRatings
                rating={4.9}
                starDimension="16px"
                starSpacing="1px"
                starRatedColor="#C5090A"
                numberOfStars={5}
                name='rating'
              />
              <span className='text-[#8888A3] text-[0.8rem] sm:text-[1rem]'>Rating</span>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <img className=''
          src={homepage3} alt="HomepageImg" />
      </div>
    </div>
  )
}
