import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function AmendmentFiling() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"Amendment Filing"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            Amendment filing services come to the fore when you need to change your return in case of errors while filing the return. There is no denying the fact that the IRS would correct the errors on a return and may accept returns without the requirement of specific schedules or forms. In these types of cases, there is no requirement of changing your returns.
          </p>
          <p>
            If there are any errors in your income tax returns, you need to file an amendment to change your returns. In most cases, the IRS will accept or reject the corrections of the income tax returns without the requirement of specific schedules or forms. Then you are not required to change your returns. But, if you observe an alteration in your tax filing status, credits or deductions, you should file an amendment return. Usually, you need to submit form-1040x within three years after the date of the original return to claim a refund. You can also submit your returns within two years after your tax paid date.
          </p>
        </div>
      </ServicesCard>
    </div>
  )
}
