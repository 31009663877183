import React from 'react'
import homeServices from '../../assets/home/homeServices.svg'
import HomeServicesCard from '../Cards/HomeServicesCard'
import homeService1 from '../../assets/home/home-service1.svg'
import homeService2 from '../../assets/home/home-service2.png'
import homeService3 from '../../assets/home/home-service3.png'
import homeService4 from '../../assets/home/home-service4.png'
import homeService5 from '../../assets/home/home-service5.png'
import homeService6 from '../../assets/home/home-service6.png'
import homeService7 from '../../assets/home/home-service7.png'
import homeService8 from '../../assets/home/home-service8.png'

const SERVICES_CARD_DATA = [
  {
    img: homeService1,
    heading: "Federal and State Tax Filing",
    description: "We, at Eazy Refund Tax, has a dedicated E-Return Originator that is legally registered with the latest IRS Rules and Circular 230",
    path: "/services/us-individual/federal-tax-filing",
  },
  {
    img: homeService2,
    heading: "FBAR/FATCA Processing",
    description: "You will be asked to report your financial transaction details to the United States Department of Treasury on a yearly basis",
    path: "/services/us-individual/fbar-processing",
  },
  {
    img: homeService3,
    heading: "Representation Services",
    description: "If you receive the letter stating that your income tax return has been selected for an audit, you don't have to worry about it",
    path: "/services/us-individual/representation-services",
  },
  {
    img: homeService4,
    heading: "ITIN Processing",
    description: "Most of the people don't know the circumstances during which a non-US person need an ITIN and what an ITIN exactly implies",
    path: "/services/us-individual/itin-processing",
  },
  {
    img: homeService5,
    heading: "Amendment Filing",
    description: "Amendment filing services come to the fore when you need to change your return in case of errors while filing the return",
    path: "/services/us-individual/amendment-filing",
  },
  {
    img: homeService6,
    heading: "Indian Tax Filling",
    description: "Eazy Refund Tax has incorporated a dedicated processing center solely for India. It helps our Indian clients with business",
    path: "/services/us-individual/indian-tax-filing",
  },
  {
    img: homeService7,
    heading: "Entity Formation",
    description: "The first step to start a business is to decide on the structure of the business. If you fail to select the right structure",
    path: "/services/us-business/entity-formation",
  },
  {
    img: homeService8,
    heading: "Corporate Tax Filing",
    description: "The name sole ownership itself says that these businesses are owned by single persons and the owners of these companies",
    path: "/services/us-business/corporate-tax-filing",
  },
]

export default function HomeServices() {
  return (
    <div className='bg-[#FFDEDC] flex flex-col items-center max-md:py-[1rem] md:py-[2rem] px-[1rem] 
    sm:px-[2rem]'>
      <div>
        <img src={homeServices} alt="homeServicesTitle" />
      </div>
      <h1
        className='text-[1.25rem] sm:text-[1.5rem] lg:text-[2rem] xl:text-[2.2rem] font-bold max-md:text-center'>
        Get the best out of your company with our service
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-center gap-x-[3rem] 
      gap-y-[1rem] sm:gap-y-[2rem] my-[3rem]'>
        {
          SERVICES_CARD_DATA.map((card, i) => {
            return (
              <HomeServicesCard key={i} img={card.img} heading={card.heading} description={card.description}
                path={card.path}
              />
            )
          })
        }
      </div>
    </div>
  )
}
