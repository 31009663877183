import React from 'react'

export default function TaxBenefitsCard({ img, description }) {
    return (
        <div className='w-[150px] xs:max-sm:w-[200px] sm:max-xl:w-[250px] xl:w-[275px] h-[180px] flex flex-col items-center justify-between border border-primary py-[0.5rem] sm:py-[1rem] px-[0.4rem] rounded-lg'>
            <div className='h-[80px] w-[80px] flex justify-center items-center rounded-lg'
                style={{
                    boxShadow: "1px 1px 10px 0px rgba(0, 0, 0, 0.10)",
                }}>
                <img src={img} alt="icon" className='object-cover' />
            </div>
            <p className='text-center text-[#8888A3] text-[0.9rem] sm:text-[1rem]'>
                {description}
            </p>
        </div>
    )
}
