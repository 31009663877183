import React from 'react'
import footerIcon1 from "../assets/home/footerIcon1.svg"
import footerIcon2 from "../assets/home/footerIcon2.svg"
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='text-white bg-primary flex flex-col items-center justify-center gap-[1rem] 
        pt-[5rem] pb-[2rem]  px-[0.5rem] xs:px-[1rem]'>
            <div className='flex justify-center items-center text-[1.1rem] xs:max-md:text-[1.2rem] md:text-[1.5rem] font-[500] text-center'>
                <Link to={"terms-conditions"}>
                    <h2>
                        Terms & Conditions
                    </h2>
                </Link>
            </div>
            <p className='text-[0.9rem] xs:max-md:text-[1rem] md:text-[1.3rem] text-center'>
                Have questions or need assistance? Contact us at
                <span className='font-bold'> info@eazyrefundtax.com </span> or call us at
                <span className='font-bold'> +1 (909)-999-6214 , +917386670536</span>
            </p>
            <div className='flex flex-col sm:flex-row items-center justify-center gap-[1rem]'>
                <p className='text-[0.8rem] xs:max-md:text-[1rem] md:text-[1.1rem] text-center'>
                    © Eazy Refund Tax 2023. All rights Reserved.
                </p>
                <div className='flex max-sm:gap-[2rem] sm:gap-[1rem]'>
                    <img src={footerIcon1} alt="" />
                    <img src={footerIcon2} alt="" />
                </div>
            </div>
        </div>
    )
}