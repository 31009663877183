import React, { useEffect } from 'react'
import Homepage from './Homepage'
import HomeAbout from './HomeAbout'
import HomeServices from './HomeServices'
import TaxBenefits from './TaxBenefits'
import GetInTouch from './GetInTouch'

export default function HomeContainer() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [])

    return (
        <div className=''>
            <Homepage />
            <HomeAbout />
            <HomeServices />
            <TaxBenefits />
            <GetInTouch />
        </div>
    )
}
