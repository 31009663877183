import React from 'react'
import leafImg from '../../assets/home/leafImg.svg'
import './serviceStyles.css';

export default function ServicesCard(props) {
    return (
        <div className=''>
            <div className='flex flex-col items-center justify-center h-[200px] sm:h-[300px] bg-no-repeat bg-center bg-cover custom-bg-container text-white px-[1rem] text-center'
                style={{
                    backgroundImage: `url(${props.imgSrc})`,
                }}
            >
                <h1 className='text-[2.5rem] xs:max-sm:text-[3rem] sm:text-[3.2rem] lg:text-[3.5rem] font-bold'>
                    Our Services
                </h1>
                <p className='text-[1.2rem] xs:max-sm:text-[1.5rem] sm:text-[1.7rem] lg:text-[2rem] font-[500] max-md:py-[1rem]'>
                    Get the best out of your company with our service
                </p>
            </div>
            <div className='px-[1rem] sm:px-[3rem] my-[1rem] mb-[3rem]'>
                <div className='flex items-center'>
                    <h1 className='font-bold text-[1.6rem] xs:max-lg:text-[1.8rem] lg:text-[2.3rem] text-primary'>
                        {props.heading}
                    </h1>
                    <img src={leafImg} alt="leaf" className='max-md:hidden' />
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
