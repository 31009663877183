import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function EntityFormation() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"Entity Formation"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            The first step to start a business is to decide on the structure of the business. If you fail to select the right structure for your business, there are high chances that your business incur thousands in taxes which will ultimately result in the failure of the company. But, choosing the right structure among the various available options is difficult and requires more research.
          </p>
          <p>
            The experts working at the Eazy Refund Tax interact with you to understand your needs and requirements and assist you in choosing the right entity structure for your business. We also help you in creating and submitting forms and documents that are required to form your entities. Whatever be the entity you are willing to form, corporations, LLC or partnerships, we are here to help you in every aspect of entity formation.
          </p>
        </div>
      </ServicesCard>
    </div>
  )
}
