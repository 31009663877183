import React from 'react'
import ServicesCard from '../Cards/ServicesCard'
import service1 from '../../assets/services/service-1.svg'

export default function RepresentationServices() {
  return (
    <div>
      <ServicesCard imgSrc={service1} heading={"Representation Services"}>
        <div className='flex flex-col gap-[1.5rem] text-[1rem] sm:text-[1.1rem] lg:text-[1.3rem] lg:leading-8 font-[500] mt-[1rem]'>
          <p>
            If you receive the letter stating that your income tax return has been selected for an audit, you don't have to worry about it. Eazy Refund Tax will be there for you to assist you in both personal and business tax credits. Even if you are sure that you didn't do anything wrong, sometimes you have to prove yourself during an audit. For this, it is always better to hire an expert.
          </p>
          <p>
            Our experts offer you professional representation services to help you deal with these kinds of issues In case of personal audits, you should comprehend that the returns are chosen for data analysis. If you hire us, we will take responsibility and act as a mediator between you and the state.
          </p>
        </div>
      </ServicesCard>
    </div>
  )
}
