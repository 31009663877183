import React, { useEffect } from 'react'
import './refundStatusStyles.css'

export default function RefundStatus() {

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      })
   }, [])

   return (
      <div className='px-[0rem] sm:max-md:px-[1rem] md:px-[3rem] my-0 sm:my-[1rem] overflow-auto'>
         <h1 className='text-primary text-[2.2rem] lg:text-[2.5rem] max-md:text-center font-bold my-[1rem]'>
            Refund Status
         </h1>
         <table className='w-full custom-table-container'>
            <tbody className='text-[0.85rem] sm:max-lg:text-[1rem] lg:text-[1rem]'>
               <tr className=''>
                  <td className='w-[15%]'>New Jersey</td>
                  <td className=''>
                     <a href='https://www16.state.nj.us/TYTR_TGI_INQ/servlet/COMMON/prompt' target='blank' className='text-[#29BCE1] hover:text-black link:text-black'>
                        https://www16.state.nj.us/TYTR_TGI_INQ/servlet/COMMON/prompt
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Alabama</td>
                  <td className=''>
                     <a href='https://myalabamataxes.alabama.gov/_/#5' target='blank' className='text-[#29BCE1] hover:text-black active:text-black'>
                        https://myalabamataxes.alabama.gov/_/#5
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Alaska</td>
                  <td className=''>Tax Free</td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Arkansas</td>
                  <td className=''>
                     <a href='https://atap.arkansas.gov/_/#1' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://atap.arkansas.gov/_/#1
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>California</td>
                  <td className=''>
                     <a href='https://webapp.ftb.ca.gov/refund/login.aspx?Lang=en-US' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://webapp.ftb.ca.gov/refund/login.aspx?Lang=en-US
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Colorado</td>
                  <td className=''>
                     <a href='https://www.colorado.gov/revenueonline/_/#1' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://www.colorado.gov/revenueonline/_/#1
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Connecticut</td>
                  <td className=''>
                     <a href='https://drsindtax.ct.gov/AUT/welcomeindividual.aspx' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://drsindtax.ct.gov/AUT/welcomeindividual.aspx
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Delaware</td>
                  <td className=''>
                     <a href='https://dorweb.revenue.delaware.gov/scripts/refinq/refinq.dll' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://dorweb.revenue.delaware.gov/scripts/refinq/refinq.dll
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Florida</td>
                  <td className=''>Tax Free</td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Hawaii</td>
                  <td className=''>
                     <a href='https://tax.ehawaii.gov/hoihoi/refund.html' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://tax.ehawaii.gov/hoihoi/refund.html
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Idaho</td>
                  <td className=''>
                     <a href='https://idahotap.gentax.com/TAP/_/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://idahotap.gentax.com/TAP/_/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Illinois</td>
                  <td className=''>
                     <a href='https://mytax.illinois.gov/_/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://mytax.illinois.gov/_/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Indiana</td>
                  <td className=''>
                     <a href='https://secure.in.gov/apps/dor/tax/refund/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://secure.in.gov/apps/dor/tax/refund/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Kentucky</td>
                  <td className=''>
                     <a href='https://iitrefundstatus.ky.gov/TRFWeb/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://iitrefundstatus.ky.gov/TRFWeb/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Maine</td>
                  <td className=''>
                     <a href='https://portal.maine.gov/refundstatus/refund' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://portal.maine.gov/refundstatus/refund
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Maryland</td>
                  <td className=''>
                     <a href='https://interactive.marylandtaxes.com/INDIV/refundstatus/home.aspx' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://interactive.marylandtaxes.com/INDIV/refundstatus/home.aspx
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Massachusetts</td>
                  <td className=''>
                     <a href='https://www.mass.gov/dor/e-services/masstaxconnect.html' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://www.mass.gov/dor/e-services/masstaxconnect.html
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Michigan</td>
                  <td className=''>
                     <a href='https://treas-secure.treas.state.mi.us/eservice_enu/start.swe?SWECmd=Start&SWEHo=treas-secure.treas.state.mi.us' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://treas-secure.treas.state.mi.us/eservice_enu/start.swe?SWECmd=Start&SWEHo=treas-secure.treas.state.mi.us
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Minnesota</td>
                  <td className=''>
                     <a href='https://www.mndor.state.mn.us/tp/refund/_/#1' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://www.mndor.state.mn.us/tp/refund/_/#1
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Mississippi</td>
                  <td className=''>
                     <a href='https://tap.dor.ms.gov/_/#2' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://tap.dor.ms.gov/_/#2
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Missouri</td>
                  <td className=''>
                     <a href='https://dors.mo.gov/tax/taxinq/welcome.jsp' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://dors.mo.gov/tax/taxinq/welcome.jsp
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Montana</td>
                  <td className=''>
                     <a href='	https://tap.dor.mt.gov/_/#1' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://tap.dor.mt.gov/_/#1
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Nebraska</td>
                  <td className=''>
                     <a href='https://ndr-refundstatus.ne.gov/refundstatus/public/search.faces' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://ndr-refundstatus.ne.gov/refundstatus/public/search.faces
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Nevada</td>
                  <td className=''> Tax Free </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>New Hampshire	</td>
                  <td className=''> Tax Free </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>New York	</td>
                  <td className=''>
                     <a href='https://www8.tax.ny.gov/PRIS/prisStart' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://www8.tax.ny.gov/PRIS/prisStart
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>North Carolina</td>
                  <td className=''>
                     <a href='https://eservices.dor.nc.gov/wheresmyrefund/refundinput.jsp' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://eservices.dor.nc.gov/wheresmyrefund/refundinput.jsp
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>North Dakota</td>
                  <td className=''>
                     <a href='https://apps.nd.gov/tax/tap/_/#1' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://apps.nd.gov/tax/tap/_/#1
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Oregon</td>
                  <td className=''>
                     <a href='https://revenueonline.dor.oregon.gov/tap/_/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://revenueonline.dor.oregon.gov/tap/_/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Rhode Island</td>
                  <td className=''>
                     <a href='https://www.ri.gov/taxation/refund/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://www.ri.gov/taxation/refund/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>South Carolina</td>
                  <td className=''>
                     <a href='https://dor.sc.gov/tax/refund-call' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://dor.sc.gov/tax/refund-call
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>South Dakota</td>
                  <td className=''>Tax Free</td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Tennessee</td>
                  <td className=''>Tax Free</td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Texas</td>
                  <td className=''>Tax Free</td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Vermont</td>
                  <td className=''>
                     <a href='https://myvtax.vermont.gov/_/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://myvtax.vermont.gov/_/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Virginia</td>
                  <td className=''>
                     <a href='https://www.individual.tax.virginia.gov/VTOL/IndRefundStatus.seam' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://www.individual.tax.virginia.gov/VTOL/IndRefundStatus.seam
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Washington</td>
                  <td className=''>Tax Free</td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>West Virginia</td>
                  <td className=''>
                     <a href='https://mytaxes.wvtax.gov/_/#1' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://mytaxes.wvtax.gov/_/#1
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Wisconsin</td>
                  <td className=''>
                     <a href='https://ww2.revenue.wi.gov/RefundInquiry/' target='blank' className='text-[#29BCE1] hover:text-black'>
                        https://ww2.revenue.wi.gov/RefundInquiry/
                     </a>
                  </td>
               </tr>
               <tr className=''>
                  <td className='w-[15%]'>Wyoming</td>
                  <td className=''>Tax Free</td>
               </tr>
            </tbody>
         </table>
      </div>
   )
}
