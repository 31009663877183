import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sideMenuOpen: false,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState: initialState,
    reducers: {
        toggleNavbar(state) {
            state.sideMenuOpen = !state.sideMenuOpen;
        }
    }
})

export const { toggleNavbar } = layoutSlice.actions;

export default layoutSlice.reducer;